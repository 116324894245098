/* eslint-disable camelcase */

// TODO: Нужен рефактор на debounce отправки данных на сервер
// https://rtrack.ru/projects/vshsber/issues/477
import _debounce from 'lodash/debounce.js';
import { keyBy } from 'lodash';
import {
    addPageActivityQuestion,
    changePageActivityQuestion,
    restorePageActivityQuestion,
    removePageActivityQuestion,
    sortPageActivityQuestion,
    addPageActivityQuestionAnswer,
    removePageActivityQuestionAnswer,
    restorePageActivityQuestionAnswer,
    changePageActivityQuestionAnswer,
    sortPageActivityQuestionAnswer,
    copyPageActivityQuestion,
    getPageActivityQuestionImportExample,
    importPageActivityQuestions,
    exportPageActivityQuestions,
    exportPageActivityQuestionsWithAnswers,
    removePageActivityQuestionAnswers,
} from '@/api/activityQuestionApi.js';
import { downloadFile, getHtmlText, getNewBlockInstance, getUploadProgress } from '@/utils/utils.js';
import { createCancelHttp, isHttpCancel } from '@/utils/http.js';
import { DEBOUNCE_TIME, DEBOUNCE_TIME_CHANGE_ANSWER } from '@/constants/index.js';
import {
    TestQuizTypes,
    QUESTION_TYPES,
    MATCHING_QUESTION_COLUMN_TYPES,
    VISIBILITY_MIN_ANSWERS, MIN_ANSWER_LEVEL, DEFAULT_LIKERT_ANSWERS_LENGTH,
} from '@/components/Blocks/BlockTestOrQuiz/constants.js';
import { getDefaultQuestionOptions } from '@/components/Blocks/BlockTestOrQuiz/helpers.js';
import useSnackbar from '@/hooks/snackbars.js';
import { validateFile } from '@/utils/validators.js';
import { ACCEPT_FILE_IMPORT_TYPES } from '@editor-settings/views/TestOrQuizSettings/contants.js';
import { getErrorMsg } from '@/utils/helpers.js';

const { createSnackbar } = useSnackbar();

const showError = err => {
    console.error(err);

    createSnackbar({
        type: 'error',
        message: getErrorMsg(err) || 'Ошибка. Попробуйте еще раз',
    });
};

const debouncedChangeActivityAnswer = {};

const createDebouncedChangeActivityAnswer = () => _debounce(async ({ dispatch }, { activityId, questionId, answerId, answerData, cancelToken }) => {
    try {
        const resp = await changePageActivityQuestionAnswer(
            questionId,
            answerId,
            answerData,
            {
                cancelToken,
            },
        );

        dispatch('saveActivityQuestionAnswer', {
            activityId,
            questionId,
            answerId,
            answerData: {
                ...answerData,
                ...resp.data,
            },
        });

        dispatch('setEditabledAnswerId', null);

        dispatch('updateInitialStructure');
    } catch (e) {
        if (!isHttpCancel(e)) {
            dispatch('setEditabledAnswerId', null);
            console.error('[createDebouncedChangeActivityAnswer action] save question answer error', e);
        }
    }
}, DEBOUNCE_TIME_CHANGE_ANSWER);

export const getCountAnswersShouldAnswered = question => {
    if (question?.type === QUESTION_TYPES.GAPS_IN_TEXT) return question.answers?.length || 1;

    return question?.answers.filter(answer => {
        if (answer.data) {
            try {
                const answerData = JSON.parse(answer.data);

                if ('side' in answerData) {
                    return answerData.side === 'left' && Boolean(answerData.correctAnswerId?.length);
                }
            } catch (e) {
                console.error(e);

                return false;
            }
        }

        return answer.is_correct;
    }).length || 1;
};

const getNormalizeQuestionData = question => {
    const shouldNormalize = VISIBILITY_MIN_ANSWERS.includes(question.type);

    if (!shouldNormalize) return question;

    const countAnswersShouldAnswered = getCountAnswersShouldAnswered(question);
    const questionData = JSON.parse(question.question_data);

    const minAnswersLevel = questionData.minAnswersLevel
        ? Math.max(Math.min(questionData.minAnswersLevel, countAnswersShouldAnswered), MIN_ANSWER_LEVEL)
        : countAnswersShouldAnswered;

    return {
        ...question,
        question_data: JSON.stringify({
            ...questionData,
            minAnswersLevel,
        }),
    };
};

/**
 * @type {import('vuex').ActionTree<any, any>}
 */
const activityQuestionActions = {
    pushActivityQuestion({ commit, getters }, { activityId, questionData }) {
        const currentActivity = getters.getActivity(activityId);
        const newActivity = {
            ...currentActivity,
            questions: [
                ...currentActivity.questions,
                questionData,
            ],
        };

        commit('CHANGE_ACTIVITY', newActivity);
    },
    replaceActivityQuestion({ commit, getters }, { activityId, questionId, questionData }) {
        const currentActivity = getters.getActivity(activityId);
        const newActivity = {
            ...currentActivity,
            questions: currentActivity.questions.map(item => (
                item.id === questionId ? questionData : item
            )),
        };

        commit('CHANGE_ACTIVITY', newActivity);
    },

    async addActivityQuestion({ dispatch, getters }, payload) {
        try {
            const { activityId, insertAfterQuestionId } = payload;
            const currentActivity = getters.getActivity(activityId);

            const newQuestion = {
                title: '',
                description: '',
                type: QUESTION_TYPES.SINGLE_CHOICE,
                question_data: JSON.stringify(getDefaultQuestionOptions({
                    blockType: currentActivity.data?.content?.type,
                    questionType: QUESTION_TYPES.SINGLE_CHOICE,
                })),
                answers: [
                    {
                        title: '',
                        is_correct: 1, // 1|0
                        order: 1,
                    },
                    {
                        title: '',
                        is_correct: 0,
                        order: 2,
                    },
                ],
            };

            const { data: responseData } = await addPageActivityQuestion(activityId, newQuestion);
            const createdQuestion = responseData.data;

            if (insertAfterQuestionId) {
                const questionsOrderIds = currentActivity.questions.map(q => q.id);
                const indexQuestionBefore = questionsOrderIds.indexOf(insertAfterQuestionId);
                const insertIndex = indexQuestionBefore + 1;
                const newQuestionsOrderIds = questionsOrderIds.toSpliced(insertIndex, 0, createdQuestion.id);

                await dispatch('sortActivityQuestion', {
                    activityId,
                    order: newQuestionsOrderIds,
                });

                createdQuestion.order = insertIndex;
            } else {
                await dispatch('pushActivityQuestion', {
                    activityId,
                    questionData: createdQuestion,
                });
            }

            return createdQuestion;
        } catch (error) {
            console.error('[addActivityQuestion action] add question error', error);

            return Promise.reject(error);
        }
    },

    resaveActivityQuestion({ dispatch, getters }, { activityId, questionId }) {
        const currentActivity = getters.getActivity(activityId);
        const currentQuestion = currentActivity.questions.find(item => item.id === questionId);

        dispatch('changeActivityQuestion', {
            activityId,
            questionId,
            questionData: currentQuestion,
        });
    },

    changeActivityQuestion({ dispatch }, { activityId, questionId, questionData, withAnswers }) {
        const normalizeQuestionData = getNormalizeQuestionData(questionData);

        dispatch('replaceActivityQuestion', {
            activityId,
            questionId,
            questionData: normalizeQuestionData,
        });

        dispatch('debouncedSaveActivityQuestion', {
            activityId,
            questionId,
            withAnswers,
            cancelToken: createCancelHttp('cancelTokenSaveActivityQuestion').token,
        });
    },

    debouncedSaveActivityQuestion: _debounce(({ dispatch }, payload) => {
        dispatch('saveActivityQuestion', payload);
    }, DEBOUNCE_TIME),

    async saveActivityQuestion({ dispatch, getters }, { activityId, questionId, withAnswers, cancelToken }) {
        try {
            const currentActivity = getters.getActivity(activityId);
            const questionWithAnswers = currentActivity.questions.find(item => item.id === questionId);

            const { answers, ...questionWithoutAnswers } = questionWithAnswers;

            const questionData = withAnswers ? questionWithAnswers : questionWithoutAnswers;

            const { data } = await changePageActivityQuestion(activityId, questionId, questionData, { cancelToken });

            dispatch('replaceActivityQuestion', {
                activityId,
                questionId,
                questionData: data.data,
            });
            dispatch('updateInitialStructure');
        } catch (e) {
            if (isHttpCancel(e)) return;

            console.error('[saveActivityQuestion]', e);

            createSnackbar({
                type: 'error',
                message: 'Ошибка сохранения вопроса',
            });
        }
    },

    async copyActivityQuestion({ commit, getters }, { activityId, questionId }) {
        try {
            const { data } = await copyPageActivityQuestion(activityId, questionId);

            const currentActivity = getters.getActivity(activityId);
            const questions = [...currentActivity.questions];
            const matchQuestionIdx = questions.findIndex(el => el.id === questionId);

            questions.splice(matchQuestionIdx + 1, 0, data.data);

            const newActivity = {
                ...currentActivity,
                questions,
            };

            commit('CHANGE_ACTIVITY', newActivity);

            return Promise.resolve(data.data);
        } catch (error) {
            console.error('[copyActivityQuestion action] copy question error', error);

            return Promise.reject(error);
        }
    },
    async removeActivityQuestion({ commit, getters }, { activityId, questionId }) {
        try {
            await removePageActivityQuestion(activityId, questionId);

            const currentActivity = getters.getActivity(activityId);
            const newActivity = {
                ...currentActivity,
                questions: currentActivity.questions.filter(item => item.id !== questionId),
            };

            commit('CHANGE_ACTIVITY', newActivity);

            return Promise.resolve();
        } catch (error) {
            console.error('[removeActivityQuestion action] remove question error', error);

            return Promise.reject(error);
        }
    },
    async restoreActivityQuestion({ commit, getters }, { activityId, questionId }) {
        try {
            const { data } = await restorePageActivityQuestion(activityId, questionId);

            const currentActivity = getters.getActivity(activityId);
            const restoredQuestion = data.data;

            const newActivity = {
                ...currentActivity,
                questions: [restoredQuestion, ...currentActivity.questions],
            };

            newActivity.questions.sort((a, b) => a.order - b.order);

            commit('CHANGE_ACTIVITY', newActivity);

            return Promise.resolve(data.data);
        } catch (error) {
            console.error('[restoreActivityQuestion action] restore question error', error);

            return Promise.reject(error);
        }
    },
    async sortActivityQuestion({ commit, getters }, { activityId, order }) {
        try {
            const { data } = await sortPageActivityQuestion(activityId, order);
            const currentActivity = getters.getActivity(activityId);
            const newActivity = {
                ...currentActivity,
                questions: data.data,
            };

            commit('CHANGE_ACTIVITY', newActivity);

            return Promise.resolve();
        } catch (error) {
            console.error('[sortActivityQuestion action] sort question error', error);

            return Promise.reject(error);
        }
    },
    async addActivityQuestionAnswer({ dispatch, commit, getters }, { activityId, questionId, answerData = null }) {
        try {
            const currentActivity = getters.getActivity(activityId);
            const currentQuestion = currentActivity.questions.find(q => q.id === questionId);

            let maxOrder = currentQuestion.answers?.reduce((accOrder, answer) => Math.max(accOrder, answer.order ?? 0), 0);

            if (answerData?.parent_id) {
                maxOrder = currentQuestion
                    .answers
                    ?.find?.(answer => answer.id === answerData?.parent_id)
                    ?.children?.reduce((accOrder, childAnswer) => Math.max(accOrder, childAnswer.order ?? 0), 0);
            }

            const order = Math.max(maxOrder ?? -1, 0) + 1;

            const { data } = await addPageActivityQuestionAnswer(questionId, {
                parent_id: answerData?.parent_id,
                is_correct: 0,
                data: answerData?.parent_id ? null : answerData,
                order,
            });

            let newAnswer = data;

            if (answerData && typeof answerData === 'string') {
                try {
                    const parsedAnswerData = JSON.parse(answerData);

                    if (parsedAnswerData.withChildren) {
                        const { data: childrenAnswer1 } = await addPageActivityQuestionAnswer(questionId, {
                            parent_id: newAnswer.id,
                            is_correct: 1,
                            data: null,
                            order: 0,
                        });
                        const { data: childrenAnswer2 } = await addPageActivityQuestionAnswer(questionId, {
                            parent_id: newAnswer.id,
                            is_correct: 0,
                            data: null,
                            order: 1,
                        });

                        newAnswer = {
                            ...newAnswer,
                            children: [childrenAnswer1, childrenAnswer2],
                        };
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            const newActivity = {
                ...currentActivity,
                questions: currentActivity.questions.map(item => {
                    if (item.id === questionId) {
                        if (answerData?.parent_id) {
                            item.answers = item.answers.map(answer => {
                                if (answer.id === answerData?.parent_id) {
                                    return {
                                        ...answer,
                                        children: [
                                            ...(answer.children ?? []),
                                            newAnswer,
                                        ],
                                    };
                                }

                                return answer;
                            });
                        } else {
                            item.answers = [
                                ...item.answers,
                                newAnswer,
                            ];
                        }
                    }

                    return item;
                }),
            };

            commit('CHANGE_ACTIVITY', newActivity);

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
            });

            return Promise.resolve(data);
        } catch (error) {
            console.error('[addActivityQuestionAnswer action] add question answer error', error);

            return Promise.reject(error);
        }
    },
    async switchAnswerWithChildCorrectAnswer({ dispatch, commit, getters }, { activityId, questionId, switchData }) {
        try {
            const currentActivity = getters.getActivity(activityId);

            if (switchData.new?.id) {
                await changePageActivityQuestionAnswer(
                    questionId,
                    switchData.new?.id,
                    {
                        ...switchData.new,
                        is_correct: 1,
                    },
                );
            }

            if (switchData.current?.id) {
                await changePageActivityQuestionAnswer(
                    questionId,
                    switchData.current?.id,
                    {
                        ...switchData.current,
                        is_correct: 0,
                    },
                );
            }

            const newActivity = {
                ...currentActivity,
                questions: currentActivity.questions.map(item => {
                    if (item.id === questionId) {
                        return {
                            ...item,
                            answers: item.answers.map(answer => {
                                if (answer.children && answer.id === switchData.parent?.id) {
                                    return {
                                        ...answer,
                                        children: answer.children.map(child => {
                                            if (child.id === switchData.new?.id) {
                                                return {
                                                    ...child,
                                                    is_correct: 1,
                                                };
                                            }

                                            if (child.id === switchData.current?.id) {
                                                return {
                                                    ...child,
                                                    is_correct: 0,
                                                };
                                            }

                                            return child;
                                        }),
                                    };
                                }

                                return answer;
                            }),
                        };
                    }

                    return item;
                }),
            };

            commit('CHANGE_ACTIVITY', newActivity);

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
            });
        } catch (error) {
            console.error('[switchAnswerWithChildCorrectAnswer action] switch answer withchild correct error', error);

            return Promise.reject(error);
        }
    },
    sendDebouncedActivityQuestionAnswer(ctx, payload) {
        const { activityId, questionId, answerId } = payload;
        const debounceKey = [activityId, questionId, answerId].join('_');

        if (!debouncedChangeActivityAnswer[debounceKey]) {
            debouncedChangeActivityAnswer[debounceKey] = createDebouncedChangeActivityAnswer();
        }

        debouncedChangeActivityAnswer[debounceKey](ctx, {
            ...payload,
            cancelToken: createCancelHttp(`saveActivityQuestionAnswer${answerId}`).token,
        });
    },
    saveActivityQuestionAnswer({ commit, getters }, { activityId, questionId, answerData }) {
        const currentActivity = getters.getActivity(activityId);

        const newActivity = {
            ...currentActivity,
            questions: currentActivity.questions.map(item => {
                if (item.id === questionId) {
                    return {
                        ...item,
                        answers: item.answers.map(answer => {
                            if (answerData.parent_id && answer.id === answerData.parent_id) {
                                return {
                                    ...answer,
                                    children: answer.children.map(child => {
                                        if (child.id === answerData.id) {
                                            return {
                                                ...child,
                                                ...answerData,
                                            };
                                        }

                                        return child;
                                    }),
                                };
                            }

                            if (answer.id === answerData.id) {
                                return {
                                    ...answer,
                                    ...answerData,
                                };
                            }

                            return answer;
                        }),
                    };
                }

                return item;
            }),
        };

        commit('CHANGE_ACTIVITY', newActivity);
    },
    changeActivityQuestionAnswer({ dispatch }, { activityId, questionId, answerId, answerData }) {
        try {
            dispatch('setEditabledAnswerId', questionId);

            dispatch('saveActivityQuestionAnswer', {
                activityId,
                questionId,
                answerId,
                answerData,
            });

            dispatch('sendDebouncedActivityQuestionAnswer', {
                activityId,
                questionId,
                answerId,
                answerData,
            });

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
            });

            return Promise.resolve();
        } catch (error) {
            console.error('[addActivityQuestionAnswer action] add question answer error', error);

            return Promise.reject(error);
        }
    },
    async removeActivityQuestionAnswer({ dispatch, commit, getters }, { activityId, questionId, answerData }) {
        try {
            const currentActivity = getters.getActivity(activityId);

            await removePageActivityQuestionAnswer(questionId, answerData.id);

            if (answerData.parent?.id) {
                await changePageActivityQuestionAnswer(
                    questionId,
                    answerData.parent?.id,
                    answerData.parent,
                );
            }

            const newActivity = {
                ...currentActivity,
                questions: currentActivity.questions.map(question => {
                    if (question.id === questionId) {
                        const answers = answerData.parent?.id
                            ? question.answers.map(answer => {
                                if (answer.children && answer.id === answerData.parent.id) {
                                    return {
                                        ...answerData.parent,
                                        children: answer.children.filter(child => child.id !== answerData.id),
                                    };
                                }

                                return answer;
                            })
                            : question.answers.filter(answer => answer.id !== answerData.id);

                        return {
                            ...question,
                            answers,
                        };
                    }

                    return question;
                }),
            };

            commit('CHANGE_ACTIVITY', newActivity);

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
            });

            return Promise.resolve();
        } catch (error) {
            console.error('[removeActivityQuestionAnswer action] remove question answer error', error);

            return Promise.reject(error);
        }
    },
    async restoreActivityQuestionAnswer({ commit, dispatch, getters }, { activityId, questionId, answerData, answerIndex }) {
        try {
            const currentActivity = getters.getActivity(activityId);
            const { data } = await restorePageActivityQuestionAnswer(questionId, answerData.id);

            if (answerData.parent?.id) {
                const currQuestion = currentActivity.questions.find(item => item.id === questionId);
                const currQuestionAnswer = currQuestion?.answers.find(item => item.id === answerData.parent?.id);

                if (currQuestionAnswer && currQuestionAnswer.children) {
                    currQuestionAnswer.children.splice(answerIndex || -1, 0, data);

                    dispatch('changeActivityQuestionAnswer', {
                        activityId,
                        questionId,
                        answerId: answerData.parent?.id,
                        answerData: {
                            ...currQuestionAnswer,
                            data: JSON.stringify({
                                ...JSON.parse(currQuestionAnswer.data),
                                sortData: currQuestionAnswer.children.map(item => item.id),
                            }),
                        },
                    });
                }
            } else {

                const newActivity = {
                    ...currentActivity,
                    questions: currentActivity.questions.map(item => {
                        if (item.id === questionId) {
                            item.answers.splice(answerIndex, 0, data);
                        }

                        return item;
                    }),
                };

                commit('CHANGE_ACTIVITY', newActivity);
            }

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
            });

            return Promise.resolve(data);
        } catch (error) {
            console.error('[restoreActivityQuestionAnswer action] restore question answer error', error);

            return Promise.reject(error);
        }
    },
    async sortActivityQuestionAnswers({ commit, getters }, { activityId, questionId, order }) {
        try {
            const currentActivity = getters.getActivity(activityId);
            const newActivity1 = {
                ...currentActivity,
                questions: currentActivity.questions.map(item => {
                    if (item.id === questionId) {
                        const answersByIds = keyBy(item.answers, 'id');

                        return {
                            ...item,
                            answers: order.map(id => answersByIds[id]).filter(Boolean),
                        };
                    }

                    return item;
                }),
            };

            commit('CHANGE_ACTIVITY', newActivity1);

            const { data } = await sortPageActivityQuestionAnswer(questionId, order);

            const newActivity2 = {
                ...currentActivity,
                questions: currentActivity.questions.map(item => {
                    if (item.id === questionId) {
                        return {
                            ...item,
                            answers: data.data,
                        };
                    }

                    return item;
                }),
            };

            commit('CHANGE_ACTIVITY', newActivity2);


            return Promise.resolve();
        } catch (error) {
            console.error('[sortActivityQuestionAnswers action] sort question answer error', error);

            return Promise.reject(error);
        }
    },

    /**
     * TODO: удалить, вроде нигде не используется
     */
    async removeQuestionAnswers({ dispatch, commit, getters }, { activityId, questionId }) {
        try {
            await removePageActivityQuestionAnswers(questionId);

            const currentActivity = getters.getActivity(activityId);
            const newActivity = {
                ...currentActivity,
                questions: currentActivity.questions.map(item => {
                    if (item.id === questionId) {
                        return {
                            ...item,
                            answers: [],
                        };
                    }

                    return item;
                }),
            };

            commit('CHANGE_ACTIVITY', newActivity);

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
            });

            return Promise.resolve();
        } catch (error) {
            console.error('[removeQuestionAnswers action] removing answers', error);

            return Promise.reject(error);
        }
    },

    async changeTestQuizType({ dispatch, getters }, payload) {
        const { blockId, type: newType } = payload;
        const block = getters.getActivity(blockId);
        const options = block?.data?.content?.options ?? {};
        const defaultOptions = {
            questionsToSend: options.questionsToSend,
            passingMinimum: null,
            shakeQuestions: options.shakeQuestions,
            linearPassing: false,
            earlyCompletion: false,
            showResultsStats: false,
            totalReaction: {
                positive: null,
                negative: null,
            },
        };
        let newOptions = null;

        if (newType === TestQuizTypes.TEST) {
            newOptions = {
                ...defaultOptions,
                detailedResults: {
                    type: 'userOnly',
                    hideRightAnswers: false,
                },
            };
        } else {
            newOptions = {
                ...defaultOptions,
                detailedResults: null,
            };
        }

        let newBlock = getNewBlockInstance(block, newType, '/data/content/type');

        newBlock = getNewBlockInstance(newBlock, newOptions, '/data/content/options');

        if (newType === TestQuizTypes.INTERVIEW) newBlock = getNewBlockInstance(newBlock, null, '/duration');

        const isQuestionForChange = question => [
            QUESTION_TYPES.LIKERT_SCALE,
            QUESTION_TYPES.OPEN_QUESTION,
            QUESTION_TYPES.MATCHING,
        ].includes(question.type);

        const { questions } = newBlock;

        await dispatch('changeBlock', newBlock);

        await questions.filter(isQuestionForChange).reduce(async (promise, question) => {
            await promise;

            try {
                await dispatch('changeQuestionType', {
                    blockId: newBlock.id,
                    questionId: question.id,
                    type: QUESTION_TYPES.SINGLE_CHOICE,
                });
            } catch (e) {
                console.error('[changeQuestionTime seq]', e);
            }
        }, Promise.resolve());
    },
    async downloadTemplate(_, { blockId }) {
        const { data } = await getPageActivityQuestionImportExample(blockId);

        downloadFile(data);
    },

    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {number} payload.blockId
     * @returns {Promise<void>}
     */
    async exportXlsx(_, payload) {
        const { data } = await exportPageActivityQuestions(payload.blockId);

        downloadFile(data);
    },

    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {number} payload.blockId
     * @param {number} payload.devCenterId
     * @returns {Promise<void>}
     */
    async exportTest(_, payload) {
        try {
            const { devCenterId, blockId } = payload;

            await exportPageActivityQuestionsWithAnswers(blockId);

            createSnackbar({
                title: 'Формирование отчета',
                message: 'Сводный отчет поставлен в очередь на создание. Сформированный отчет можно будет получить на странице отчетов.',
                type: 'info',
                size: 'large',
                link: {
                    text: 'Перейти к отчетам',
                    route: {
                        name: 'AdminReports',
                        params: {
                            developmentCenterId: devCenterId,
                        },
                    },
                },
            });
        } catch (e) {
            console.error(e);

            createSnackbar({
                type: 'error',
                message: 'Ошибка формирования отчета',
            });
        }
    },
    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {string|number} payload.longreadId
     * @param {string|number} payload.blockId
     * @param {File} payload.file
     * @returns {Promise<void>}
     */
    async importExcel({ dispatch, commit }, { longreadId, blockId, file }) {
        try {
            commit('IMPORT_START', { blockId });

            await validateFile(file, {
                types: ACCEPT_FILE_IMPORT_TYPES,
            });

            const changeBlockInportProgress = progress => {
                commit('changeBlockInportProgress', {
                    blockId,
                    progress,
                });
            };

            const handleProgress = event => {
                const progress = getUploadProgress(event);

                changeBlockInportProgress(progress);
            };

            const { data } = await importPageActivityQuestions(blockId, file, handleProgress);

            changeBlockInportProgress(100);

            await dispatch('refetchActivity', {
                longreadId,
                blockId,
            });

            const { total_questions_count, imported_questions_count } = data;

            if (total_questions_count === imported_questions_count) {
                createSnackbar({
                    type: 'success',
                    message: 'Импорт успешно завершен',
                    timeout: 1500,
                });
            } else {
                const snackbarTitle = `Успешно импортировано ${imported_questions_count} из ${total_questions_count}`;
                const snackbarDescription = `${total_questions_count - imported_questions_count} вопроса импортировано с ошибкой. Проверьте правильность заполнения`;

                createSnackbar({
                    type: 'info',
                    title: snackbarTitle,
                    message: snackbarDescription,
                    timeout: 1500,
                });
            }
        } catch (e) {
            showError(e);
        } finally {
            commit('IMPORT_FINISH', { blockId });
        }
    },

    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {string|number} payload.blockId
     * @param {string|number} payload.questionId
     * @param {string} payload.type
     */
    // eslint-disable-next-line complexity
    async changeQuestionType({ getters, dispatch }, payload) {
        const { blockId, questionId, type: newType } = payload;

        const block = getters.getActivity(blockId);
        const question = block?.questions?.find(q => q.id === questionId);

        if (!question) return;

        const newQuestion = {
            ...question,
            question_data: JSON.stringify(getDefaultQuestionOptions({
                blockType: block.data?.content?.type,
                questionType: newType,
            })),
            type: newType,
        };

        const getSimpleAnswer = (order, is_correct = 0) => ({
            title: '',
            is_correct,
            order,
        });

        // сбрасываем некоторые натройки блока когда информационный слайд
        if (newType === QUESTION_TYPES.INFORMATION) {
            const { questionsToSend, ...restOptions } = block.data.content.options;
            const newBlock = getNewBlockInstance(block, {
                ...restOptions,
                shakeQuestions: false,
            }, '/data/content/options');

            dispatch('changeBlock', newBlock);
        }

        if ([QUESTION_TYPES.MATCHING, QUESTION_TYPES.GAPS_IN_TEXT].includes(question.type)) {
            newQuestion.answers = [
                getSimpleAnswer(1, 1),
                getSimpleAnswer(2),
            ];
        }

        const dipatchChangeActivityQuestion = async questionData => {
            await dispatch('changeActivityQuestion', {
                activityId: blockId,
                questionId,
                questionData,
                withAnswers: true,
            });
        };

        if (newType === QUESTION_TYPES.SINGLE_CHOICE) {
            let isChanged = false;

            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: newQuestion.answers.map(item => {

                    if (item.is_correct && !isChanged) {
                        isChanged = true;

                        return item;
                    }

                    return {
                        ...item,
                        is_correct: 0,
                    };
                }),
            });
        } else if (newType === QUESTION_TYPES.LIKERT_SCALE) {
            const convertedLikertAnswers = newQuestion.answers.map(answer => ({
                ...answer,
                title: getHtmlText(answer?.title ?? ''),
                is_correct: 1,
            }));

            if (convertedLikertAnswers.length < DEFAULT_LIKERT_ANSWERS_LENGTH) {
                const diffLength = DEFAULT_LIKERT_ANSWERS_LENGTH - convertedLikertAnswers.length;
                const lastOrder = convertedLikertAnswers.length;

                for (let i = 0; i < diffLength; i++) {
                    convertedLikertAnswers.push(getSimpleAnswer(lastOrder + i));
                }
            }

            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: convertedLikertAnswers,
            });
        } else if (newType === QUESTION_TYPES.SORTING) {
            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: newQuestion.answers.map((item, idx) => ({
                    ...item,
                    order: idx + 1,
                })),
            });
        } else if (newType === QUESTION_TYPES.MATCHING) {
            const getAnswerSide = (order, side) => ({
                ...getSimpleAnswer(order),
                data: JSON.stringify({
                    correctAnswerId: null,
                    side,
                }),
            });

            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: [
                    getAnswerSide(1, MATCHING_QUESTION_COLUMN_TYPES.LEFT),
                    getAnswerSide(2, MATCHING_QUESTION_COLUMN_TYPES.LEFT),
                    getAnswerSide(3, MATCHING_QUESTION_COLUMN_TYPES.RIGHT),
                    getAnswerSide(4, MATCHING_QUESTION_COLUMN_TYPES.RIGHT),
                ],
            });
        } else if (newType === QUESTION_TYPES.GAPS_IN_TEXT) {
            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: [
                    getSimpleAnswer(1, 1),
                    getSimpleAnswer(2, 1),
                ],
            });
        } else {
            await dipatchChangeActivityQuestion(newQuestion);
        }
    },
};

export default activityQuestionActions;
